import React from 'react';
export const Welcome = React.lazy(() => import('../../pages/welcome/Welcome')); 
export const AddCustomer = React.lazy(() => import('../../pages/users/customer/add/AddCustomer')); 
export const Customer = React.lazy(() => import('../../pages/users/customer/Customer')); 
export const EditCustomer = React.lazy(() => import('../../pages/users/customer/edit/EditCustomer')); 
export const ChangePasswrod = React.lazy(() => import('../../pages/users/customer/password/ChangePasswrod')); 
export const AddDoctor = React.lazy(() => import('../../pages/users/doctor/add/AddDoctor')); 
export const EditDoctor = React.lazy(() => import('../../pages/users/doctor/edit/EditDoctor')); 

export const DeletedCustomerList = React.lazy(() => import('../../pages/users/customer/delete/DeletedCustomerList')); 
export const Doctor = React.lazy(() => import('../../pages/users/doctor/Doctor')); 
export const EcgDoctorList = React.lazy(() => import('../../pages/users/doctor/ecg/EcgDoctorList')); 
export const DeletedDoctorList = React.lazy(() => import('../../pages/users/doctor/delete/DeletedDoctorList')); 
export const Patient = React.lazy(() => import('../../pages/admin/patient/Patient')); 
export const Complete = React.lazy(() => import('../../pages/admin/patient/complete/Complete')); 
export const AdminViewPatient = React.lazy(() => import('../../pages/admin/patient/view/ViewPatient')); 
export const TodayComplete = React.lazy(() => import('../../pages/admin/patient/today/TodayComplete')); 
export const TodaySummary = React.lazy(() => import('../../pages/admin/patient/todaySummary/TodaySummary')); 
export const Monthly = React.lazy(() => import('../../pages/admin/patient/monthly/Monthly')); 
export const SelectDoctor = React.lazy(() => import('../../pages/admin/patient/selectDoctor/SelectDoctor')); 
export const ReportByMonth = React.lazy(() => import('../../pages/admin/patient/monthly/report/ReportByMonth')); 
export const DeletePatient = React.lazy(() => import('../../pages/admin/patient/delete/DeletePatient')); 

export const DoctorUpdate = React.lazy(() => import('../../pages/admin/update/doctorUpdate/DoctorUpdate')); 
export const DoctorMonthList = React.lazy(() => import('../../pages/admin/update/doctorUpdate/month/DoctorMonthList')); 
export const UpdateBill = React.lazy(() => import('../../pages/admin/update/doctorUpdate/updateBill/UpdateBill')); 
export const PrintBill = React.lazy(() => import('../../pages/admin/update/doctorUpdate/print/PrintBill')); 
export const PayBill = React.lazy(() => import('../../pages/admin/update/doctorUpdate/payBill/PayBill')); 
 
export const CustomerUpdate = React.lazy(() => import('../../pages/admin/update/customerUpdate/CustomerUpdate')); 
export const CustomerMonthList = React.lazy(() => import('../../pages/admin/update/customerUpdate/month/CustomerMonthList')); 
export const CustomerUpdateBill = React.lazy(() => import('../../pages/admin/update/customerUpdate/updateBill/CustomerUpdateBill')); 
export const CustomerPrintBill = React.lazy(() => import('../../pages/admin/update/customerUpdate/print/CustomerPrintBill')); 
export const CustomerPayBill = React.lazy(() => import('../../pages/admin/update/customerUpdate/payBill/CustomerPayBill')); 
 

export const PaymentMethod = React.lazy(() => import('../../pages/admin/paymentMethod/PaymentMethod')); 
export const AddPaymentMethod = React.lazy(() => import('../../pages/admin/paymentMethod/add/AddPaymentMethod')); 
export const AddFormate = React.lazy(() => import('../../pages/admin/formate/add/AddFormate')); 
export const Format = React.lazy(() => import('../../pages/admin/formate/Format')); 
export const AddHistory = React.lazy(() => import('../../pages/admin/history/add/AddHistory')); 
export const History = React.lazy(() => import('../../pages/admin/history/History')); 
export const AddXrayType = React.lazy(() => import('../../pages/admin/xrayType/add/AddXrayType')); 
export const XrayType = React.lazy(() => import('../../pages/admin/xrayType/XrayType')); 

export const AddPatient = React.lazy(() => import('../../pages/customer/patient/add/AddPatient')); 
export const AddPatientPrev = React.lazy(() => import('../../pages/customer/patient/add/preUpload/AddPatientPrev')); 
export const AddEcgPatientPrev = React.lazy(() => import('../../pages/customer/patient/add/preUploadEcg/AddPatientPrev')); 
export const QuickSend = React.lazy(() => import('../../pages/customer/patient/quickSend/QuickSend')); 
export const QuickSendPrev = React.lazy(() => import('../../pages/customer/patient/quickSend/QuickSendPrev')); 
export const CsPatient = React.lazy(() => import('../../pages/customer/patient/Patient')); 
export const RunningMonthPatient = React.lazy(() => import('../../pages/customer/patient/runningMonth/RunningMonthPatient')); 
export const PrevMonthPatient = React.lazy(() => import('../../pages/customer/patient/prevMonth/PrevMonthPatient')); 
export const PrevMonthAllPatient = React.lazy(() => import('../../pages/customer/patient/prevMonthAll/PrevMonthAllPatient')); 
export const PrintPatient = React.lazy(() => import('../../pages/customer/patient/print/PrintPatient')); 
export const ViewPatient = React.lazy(() => import('../../pages/customer/patient/view/ViewPatient')); 
export const EditPatient = React.lazy(() => import('../../pages/customer/patient/edit/EditPatient')); 
export const Referance = React.lazy(() => import('../../pages/customer/referance/Referance')); 
export const AddReferance = React.lazy(() => import('../../pages/customer/referance/add/AddReferance')); 
export const Bill = React.lazy(() => import('../../pages/customer/bill/Bill')); 
export const ViewBill = React.lazy(() => import('../../pages/customer/bill/view/ViewBill')); 
export const CsPayBill = React.lazy(() => import('../../pages/customer/bill/pay/PayBill')); 
export const CheckUser = React.lazy(() => import('../../pages/customer/checkuser/CheckUser')); 
export const AddNewCheckUser = React.lazy(() => import('../../pages/customer/checkuser/add/AddNewCheckUser')); 
export const CompletePatient = React.lazy(() => import('../../pages/customer/patient/complete/CompletePatient')); 
export const Software = React.lazy(() => import('../../pages/customer/software/Software')); 
export const AddEcg = React.lazy(() => import('../../pages/customer/patient/addEcg/AddEcg')); 
export const DoctorList = React.lazy(() => import('../../pages/customer/doctorlist/DoctorList'));

export const DrPatient = React.lazy(() => import('../../pages/doctor/patient/Patient')); 
export const DrViewPatient = React.lazy(() => import('../../pages/doctor/patient/view/ViewPatient')); 
export const DrEditReport = React.lazy(() => import('../../pages/doctor/patient/edit/EditReport')); 
export const DrCompletePatient= React.lazy(() => import('../../pages/doctor/patient/complete/CompletePatient')); 
export const ArchiveCompletePatient= React.lazy(() => import('../../pages/doctor/patient/archive/ArchiveCompletePatient')); 
export const DrFormat= React.lazy(() => import('../../pages/doctor/format/Format')); 
export const DrAddFormate= React.lazy(() => import('../../pages/doctor/format/add/AddFormate')); 
export const DrEditFormate= React.lazy(() => import('../../pages/doctor/format/edit/EditFormate')); 