import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../assets/logo/logo.png";
import { ToastContainer, toast } from "react-toastify";
import style from "./form.module.css"
import { login, homeURL } from "../../../../api/api";
import { authenticate } from "../../../../shared/utils/Auth";
import InputField from "../../../../shared/ui/input/InputField";

const INIT = {
  username: "",
  password: "",
}

const Form = () => {

  const [user, setUser] = useState({ ...INIT });

  /* @___ Added state for tracking first login __@ */
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (user.password.length < 6) {
      toast.error("Password should be at least 6 characters long");
      return;
    }

    try {
      const res = await login({ ...user });

      if (res && res.data && res.data.access_token) {
        authenticate(res.data.access_token);
        if (isFirstLogin) {
          /* @___ Set isFirstLogin to false after first successful login __@ */
          setIsFirstLogin(false);
          setTimeout(() => {
            /* @___ Reload the page after 1 second __@ */
            window.location.reload();
          }, 1000);
        } else {
          navigate("/");
        }

        toast.success("Login Successfully");

      } else {
        toast.error("Login response is invalid");
      }
    } catch (error) {
      toast.error("Login failed, Please type your current username and password");
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };


  return (
    <div className="login-logo">
      <a href={`${homeURL}`}>
        <img src={Logo} className="img-responsive" alt="" />
      </a>

      <form onSubmit={handleSubmit}>
        <h3>Sign in</h3>
        <div className="input_items">
          <InputField
            type="text"
            placeholder="Enter your username"
            labelText="Username"
            name="username"
            value={user.username}
            onChange={handleOnChange}
          />
          <InputField
            type="password"
            placeholder="Enter your password"
            labelText="Password"
            name="password"
            value={user.password}
            onChange={handleOnChange}
          />
          <div>
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

export default Form