import axios from "axios";

export const imageserverURL = `https://dwxserver.com`;
   
export const BASE_URL = `https://api.dwxserver.com/`;
export const appURL = `https://staging.dwxserver.com`;
export const homeURL = `https://api.dwxserver.com/login`;
const baseUrld = `https://api.dwxserver.com/users`;


/*
export const homeURL = `http://localhost:5000/login`;
export const BASE_URL = `http://localhost:5000/`;
export const appURL = `http://localhost:3000`;
 const baseUrld = `http://localhost:5000/users`;
*/
export const login = (user) =>{ 
    return axios.post(BASE_URL, user,{
        headers:{
            'Content-Type':'application/json'
        }
    } )
}




// export const addUser = (data) =>{ 
//     return axios.post(baseUrld, data,{
//         mode: "no-cors",
//         headers:{
//             'Content-Type':'application/json', 
//         }
//     } )
// }

/* @___ get request _____@ */
export const getRequest = async (url, header) => {
  try {
    const response = await fetch(`${BASE_URL}${url}`,header );
    return response.json();
  } catch (error) {
    console.error('Error making GET request:', error);
    throw error;
  }
};

/* @___ post request _____@ */
export const postRequest = async (url, body) => {
  try {
    const response = await fetch(`${BASE_URL}${url && url}`,body);
    return response.json();
  } catch (error) {
    console.error('Error making POST request:', error);
    throw error;
  }
};

/* @___ delete request _____@ */
export const deleteRequest = async (url) => {
  try {
    const response = await fetch(`${BASE_URL}/${url}`, {
      method: 'DELETE',
    });
    return response.json();
  } catch (error) {
    console.error('Error making DELETE request:', error);
    throw error;
  }
};