import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { routeList } from "./routes";
import { Suspense } from "react";
import Login from "../../pages/login/Login";
import { Loading } from "../../shared/ui/loader/Loading";
import { HeaderMeta } from "../../shared/layout/headerMeta/HeaderMeta";
import Header from "../../shared/layout/header/Header";
import Footer from "../../shared/layout/footer/Footer";
import { isAuthenticated } from '../../shared/utils/Auth';

const Routing = () => {
  return (
    <Suspense fallback={<Loading />}>
      {
        isAuthenticated() && <HeaderMeta />
      }
      {
        isAuthenticated() && <Header />
      }
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<PrivateRoute />}>
          {
            routeList && routeList.map((menu, index) => {
              return <Route path={menu.path} element={menu.Component} key={index} />
            })
          }
        </Route>
      </Routes>
      {isAuthenticated() && <Footer />}
    </Suspense>
  );
};

export default Routing;
