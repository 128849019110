import React from 'react'
import style from "./contact.module.css"
const Contact = () => {
  return (
    <div className="login-logo contact-us">
      <h3>Contact Us</h3>

      <p>
        If you need any help please call Digital Web Xray help line or send us
        on email.
      </p>

      <ul>
        <li>
          Hotline: <a href="tel:+880 1759497773">+880 1759497773</a>
        </li>
        <li>
          Any IT Support: <a href="tel: +8801867074078">+8801867074078</a>
        </li>

        <li>
          Email:
          <a href="mailto:digitalwebxray@gmail.com">
            digitalwebxray@gmail.com
          </a>
        </li>
      </ul>

      <ul className="nav navbar-nav">
        <li>
          <a href="http://www.digitalwebxray.com/welcome">About us</a>
        </li>
        <li>
          <a href="http://www.digitalwebxray.com/contact">Contact us</a>
        </li>
        <li>
          <a href="http://www.digitalwebxray.com/privacy-policy">
            Privacy policy
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Contact