import React from 'react'
import { BrowserRouter } from "react-router-dom";
import Routing from './router/Routing';
import "../assets/css/global.css"
const App = () => {
  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  )
}

export default App