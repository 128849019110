import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../../shared/utils/Auth";

const PrivateRoute = () => {
  if (isAuthenticated()) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
