
import { AddCustomer, AddDoctor, AddEcg, AddEcgPatientPrev, AddFormate, AddHistory, AddNewCheckUser, AddPatient, AddPatientPrev, AddPaymentMethod, AddReferance, AddXrayType, AdminViewPatient, ArchiveCompletePatient, Bill, ChangePasswrod, CheckUser, Complete, CompletePatient, CsPatient, CsPayBill, Customer, CustomerMonthList, CustomerPayBill, CustomerPrintBill, CustomerUpdate, CustomerUpdateBill, DeletePatient, DeletedCustomerList, DeletedDoctorList, Doctor, DoctorList, DoctorMonthList, DoctorUpdate, DrAddFormate, DrCompletePatient, DrEditFormate, DrEditReport, DrFormat, DrPatient, DrViewPatient, EcgDoctorList, EditCustomer, EditDoctor, EditPatient, Format, History, Monthly, Patient, PayBill, PaymentMethod, PrevMonthAllPatient, PrevMonthPatient, PrintBill, PrintPatient, QuickSend, QuickSendPrev, Referance, ReportByMonth, RunningMonthPatient, SelectDoctor, Software, TodayComplete, TodaySummary, UpdateBill, ViewBill, ViewPatient, Welcome, XrayType } from "./index";
 
export const routeList = [ 
  {path: '/', Component: <Welcome />},
  {path: '/admin/add-doctor', Component: <AddDoctor />},
  {path: '/admin/edit-doctor/:id', Component: <EditDoctor />},
  {path: '/admin/xray-doctors', Component: <Doctor />},
  {path: '/admin/ecg-doctors', Component: <EcgDoctorList />},
  {path: '/admin/delete-user', Component: <DeletedDoctorList />},
  
  {path: '/admin/add-user', Component: <AddCustomer />},
  {path: '/admin/edit-user/:id', Component: <EditCustomer />},
  {path: '/admin/change-password/:id', Component: <ChangePasswrod />},
  {path: '/admin/users', Component: <Customer />},
  {path: '/admin/delete-customer-list', Component: <DeletedCustomerList />},
  {path: '/admin/patient-list', Component: <Patient />},
  {path: '/admin/completed', Component: <Complete />},
  {path: '/admin/today-completed', Component: <TodayComplete />},
  {path: '/admin/customer-daily-report', Component: <TodaySummary />},
  {path: '/admin/month-list', Component: <Monthly />},
  {path: '/admin/select-dr/:id', Component: <SelectDoctor />},
  {path: '/admin/month-list/:month', Component: <ReportByMonth />},
  {path: '/admin/delete-report', Component: <DeletePatient />},
  {path: '/admin/view-patient/:id', Component: <AdminViewPatient />},

  {path: '/admin/doctor-list', Component: <DoctorUpdate />},
  {path: '/admin/update-doctor-month/:id', Component: <DoctorMonthList />},
  {path: '/admin/update-doctor-month/:id/:month', Component: <UpdateBill />},
  {path: '/admin/print-doctor-bill/:id/:month', Component: <PrintBill />},
  {path: '/admin/doctor-pay-bill/:id/:month', Component: <PayBill />},

  {path: '/admin/customer-list', Component: <CustomerUpdate />},
  {path: '/admin/update-customer-month/:id', Component: <CustomerMonthList />},
  {path: '/admin/update-customer-month/:id/:month', Component: <CustomerUpdateBill/>},
  {path: '/admin/print-customer-bill/:id/:month', Component: <CustomerPrintBill />},
  {path: '/admin/customer-pay-bill/:id/:month', Component: <CustomerPayBill />},

  {path: '/admin/payment-list', Component: <PaymentMethod />},
  {path: '/admin/create-payment', Component: <AddPaymentMethod />},
  {path: '/admin/create-formate', Component: <AddFormate />},
  {path: '/admin/view-formate', Component: <Format />},
  {path: '/admin/create-history', Component: <AddHistory />},
  {path: '/admin/view-history', Component: <History />},
  {path: '/admin/create-type', Component: <AddXrayType />},
  {path: '/admin/view-type', Component: <XrayType />},
  {path: '/customer/add-patient', Component: <AddPatient />},
  {path: '/customer/add-prev-patient', Component: <AddPatientPrev />},
  {path: '/customer/xray/add-prev-ecg-patient', Component: <AddEcgPatientPrev />},
  {path: '/customer/quick-send-default', Component: <QuickSend />},
  {path: '/customer/quick-send', Component: <QuickSendPrev />},
  {path: '/customer/xray/ecgcreate', Component: <AddEcg />},
  {path: 'customer/doctor', Component: <DoctorList />},
  {path: 'customer/patient', Component: <CsPatient />},
  {path: 'customer/running-month', Component: <RunningMonthPatient />},
  {path: 'customer/previous-report', Component: <PrevMonthPatient />},
  {path: 'customer/previous-m-report/:startDate/:endDate', Component: <PrevMonthAllPatient />},
  {path: 'customer/patient/view/:id', Component: <ViewPatient />},
  {path: 'customer/patient/edit/:id', Component: <EditPatient />},
  {path: 'customer/patient/print/:id', Component: <PrintPatient />},
  {path: 'customer/completed', Component: <CompletePatient />},
  {path: 'customer/create-referance', Component: <AddReferance />},
  {path: 'customer/references', Component: <Referance />},
  {path: 'customer/bill', Component: <Bill />},
  {path: 'customer/bill/:monthYear', Component: <ViewBill />},
  {path: 'customer/paybill/:monthYear', Component: <CsPayBill />},
  {path: 'customer/allusers', Component: <CheckUser />},
  {path: 'customer/checkby/adduser', Component: <AddNewCheckUser />},
  {path: 'customer/view-software', Component: <Software />},
 
  {path: 'doctor/patient', Component: <DrPatient />},
  {path: 'doctor/patient/:id', Component: <DrViewPatient />},
  {path: 'doctor/edit-report/:id', Component: <DrEditReport />},
  {path: 'doctor/completed', Component: <DrCompletePatient />},
  {path: 'doctor/complete-report', Component: <ArchiveCompletePatient />},
  {path: 'doctor/formate', Component: <DrFormat />},
  {path: 'doctor/formate/create', Component: <DrAddFormate />},
  {path: 'doctor/formate/:id', Component: <DrEditFormate />},
 
]