import React from 'react'
import Form from '../form/Form'
import Contact from '../contact/Contact'
import style from "./sidebar.module.css"

const Sidebar = () => {
  return (
    <div className="col-md-3 login-form-1">
    <Form />
    <Contact />
  </div>
  )
}

export default Sidebar