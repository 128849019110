import React from 'react'
import Sidebar from './helper/sidebar/Sidebar'
import Banner from './helper/banner/Banner'
import "./login.css"
const Login = () => {
  return (
    <div className="container-fluid login-container">
      <div className="row full-height">
        <Sidebar />
        <Banner />
      </div>
    </div>
  )
}

export default Login