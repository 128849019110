import React from 'react'
import { navItems } from './menuData'
import "../../../assets/css/header.css"
import Nav from './helper/Nav'
const Header = () => {
  return (
    <nav className="navbar navbar-custom admin no-print noprint admin_header_sec">
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="main-menu">
          <Nav navItems={navItems} />
        </div>
      </div>
    </nav>
  )
}

export default Header