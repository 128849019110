import jwtDecode from "jwt-decode";

export const userInfo = () => {
  if (typeof window !== "undefined") {
     if(localStorage.getItem("token") !=="undefined"){ 
    const token = JSON.parse(localStorage.getItem("token"));
    if (!token) return { userRole: "user" };
    const decode = jwtDecode(token);

    return { ...decode, token: token };
     }else{
      return { userRole: "user" }
     }
  }
};

export const checkUserRole = (requiredRole) => {
  const { userRole } = userInfo();
  return requiredRole === userRole;
};